import { configureStore } from '@reduxjs/toolkit'
import ClientDataSlice from './ClientDataSlice'
import AdminDataSlice from './AdminDataSlice'
// import AuthenticationDataSlice from './AuthenticationDataSlice'
import VendorDataSlice from './VendorDataSlice'
import DeveloperDataSlice from './DeveloperDataSlice'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'developerData',
  storage,
  whitelist: ['educationLevel', 'clientActiveStep', 'nestedActiveStep', 'developerId'],
};

const persistedDeveloperDataReducer = persistReducer(persistConfig, DeveloperDataSlice);


const combinedReducer = {
    // authData: AuthenticationDataSlice,
    clientData:ClientDataSlice,
    developerData:persistedDeveloperDataReducer,
    adminData:AdminDataSlice,
    vendorData:VendorDataSlice,
  }
  
  const store = configureStore({
    reducer: combinedReducer,
  });
  
  const persistor = persistStore(store);
  
  export { store, persistor };
  